/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import { useLocation } from "@reach/router"

function SEO({ description, lang, meta, title }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
      }
    `
  )

  const location = useLocation()

  let metaDescription = description || site.siteMetadata.description
  if (metaDescription.length > 150) {
    metaDescription =
      metaDescription.substring(0, metaDescription.lastIndexOf(" ", 150)) +
      "..."
  }

  const canonicalUrl =
    location.pathname === "/"
      ? site.siteMetadata.siteUrl.replace(/\/$/, "")
      : `${site.siteMetadata.siteUrl.replace(
          /\/$/,
          ""
        )}/${location.pathname.replace(/^\//, "")}`

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "Corporation",
    url: canonicalUrl,
    name: site.siteMetadata.title,
    author: {
      "@type": "Person",
      name: site.siteMetadata.author,
    },
    description: metaDescription,
  }

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    >
      <link rel="canonical" href={canonicalUrl} />

      <script type="application/ld+json">
        {JSON.stringify(structuredData)}
      </script>
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
